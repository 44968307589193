<template>
  <div>
    <banner/>
    <div class="layout">
      <div class="featured-list cl center-box">
        <div class="item ani-scale"
             v-for="item of list"
             :key="item.groupId"
             @click="$router.push({name:'FeaturedDetail',query:{id:item.groupId}})">
          <div class="cover">
            <img class="img" :src="item.photoUrl"/>
          </div>
          <div class="label">{{item.title}}</div>
        </div>
      </div>
      <el-pagination class="pages"
                     background
                     layout="prev, pager, next"
                     :page-count="totalPages"
                     @current-change="changePage"
                     hide-on-single-page>
      </el-pagination>
    </div>
  </div>

</template>
<script>
  import Banner from './components/Banner';
  import saveScrollPosition from '../../mixins/saveScrollPosition';
  import {getFeaturedList} from '../../api/featured';

  export default {
    name: 'Featured',
    data() {
      return {
        list: [],
        totalPages: 1
      };
    },
    components: {Banner},
    mixins: [saveScrollPosition],
    created() {
      this.getList();
    },
    methods: {
      // 获取特色团列表
      getList(page = 1) {
        const data = {page, perPage: 15};
        getFeaturedList(data).then(res => {
          const {value} = res;
          const list = value.list || [];
          this.list = list;
          this.totalPages = value.totalPages;
        });
      },
      changePage(page) {
        this.getList(page);
      }
    }
  };
</script>
<style scoped lang="less">
  .center-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }
  .featured-list{
    .item{
      display:block;width:300px;margin:30px 15px 0 15px;overflow:hidden;cursor:pointer;box-shadow:0 1px 5px 0 rgba(255, 115, 101, 0.20);border-radius:4px;
    }
    .cover{height:170px;overflow:hidden;}
    .label{height:50px;padding:0 10px;line-height:50px;text-align:center;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;color:#321908;}
  }
</style>
