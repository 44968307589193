<template>
  <el-carousel trigger="click" height="400px">
    <el-carousel-item v-for="item of banners" :key="item.bannerId">
        <router-link v-if="item.type==='local'" class="banner-item" :style="{'backgroundImage':`url(${item.bannerUrl})`}" target="_blank"
                     :to="{name:'BannerDetail',params:{id:item.bannerId}}">
        </router-link>
      <a v-if="item.type==='jump'" class="banner-item" :style="{'backgroundImage':`url(${item.bannerUrl})`}" target="_blank"
                   :href="item.jumpUrl">
      </a>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
  import {getFeaturedBanner} from '../../../api/featured';

  export default {
    name: 'Banner',
    data() {
      return {
        banners: []
      };
    },
    created() {
      this.getBanners();
    },
    methods: {
      getBanners() {
        getFeaturedBanner().then(res => {
          this.banners = res.value || [];
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .banner-item{display:block;width:100%;height:100%;background-repeat:no-repeat;background-position:center center;background-size:cover;cursor:pointer;}
</style>
