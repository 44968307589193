import request from '@/utils/request';
import {getLocale} from '../utils/language';
import {getCurrency} from '../utils';

const language = getLocale() || 'zh-hk';
const currency = getCurrency();

// 获取特色团列表
export function getFeaturedList(data) {
  return request({
    url: `/group/home/list/${language}`,
    method: 'get',
    params: data
  });
}

// 获取轮播图列表
export function getFeaturedBanner() {
  return request({
    url: '/group/home/banner/list',
    method: 'get'
  });
}

// 获取特色团详情
export function getFeaturedDetail(groupId) {
  return request({
    url: `/group/home/details/${language}/${groupId}`,
    method: 'get',
    params: {currencyCode: currency}
  });
}
